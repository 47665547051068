import { makeUrl } from '@/utils/image-url'

export function generateTitle(title, $config) {
  return title + ' | ' + $config.pageTitle
}

export function generateMetaTags({
  title,
  description,
  url,
  socialImage,
  imageWidth,
  imageHeight,
  isArticle,
  $config,
}) {
  return [
    // hid is used as unique identifier. Do not use `vmid` for it as it will not work
    {
      hid: 'description',
      name: 'description',
      content: description,
    },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: generateTitle(title, $config),
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: title,
    },
    {
      hid: 'og:description',
      name: 'og:description',
      property: 'og:description',
      content: description || '',
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: $config.baseUrl + url,
    },
    {
      name: 'og:image',
      property: 'og:image',
      content:
        socialImage && socialImage.attributes
          ? makeUrl(
              socialImage.attributes.fileId,
              socialImage.attributes.fileName,
              imageWidth,
              imageHeight,
              'c',
              $config
            )
          : socialImage,
    },
    {
      hid: 'og:type',
      property: 'og:type',
      content: isArticle ? 'article' : 'frontpage',
    },
  ]
}
