<template>
  <figure v-if="!!fileName" class="responsive-image">
    <picture>
      <source :srcset="urlMobile" :media="`(max-width: ${maxWidth}px)`" />
      <source :srcset="url" :media="`(min-width: ${minWidth}px)`" />
      <img
        :alt="alt || ''"
        :title="title || ''"
        :src="url"
        :loading="lazy ? 'lazy' : 'eager'"
        :width="width"
        :height="height"
      />
    </picture>
    <slot />
  </figure>
</template>

<script>
export default {
  name: 'ResponsiveImage',
  props: {
    baseUrls: {
      type: Array,
      required: true,
      validator(value) {
        return value && value.length > 0
      },
    },
    fileName: {
      type: String,
      required: true,
    },
    fileId: {
      type: String,
      default() {
        // Fallback-id generated from filename
        return this.fileName
          .split('')
          .map((c) => c.charCodeAt(0) - 64)
          .reduce((v, r) => v + r, 0)
      },
    },
    title: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: null,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: '#f5f5f5',
    },
    mobileWidth: {
      type: Number,
      default() {
        return this.width
      },
    },
    mobileHeight: {
      type: Number,
      default() {
        return this.height
      },
    },
    minWidth: {
      type: Number,
      default() {
        return typeof this.maxWidth === 'number' ? this.maxWidth + 1 : 768
      },
    },
    maxWidth: {
      type: Number,
      default() {
        return typeof this.minWidth === 'number' ? this.minWidth - 1 : 767
      },
    },
    focusPoint: {
      type: Object,
      default: null,
    },
    mode: {
      type: String,
      default: null,
      validator(value) {
        /**
         * Define allowed modes
         */
        const modes = [null, 'crop']
        return modes.includes(value)
      },
    },
  },
  computed: {
    baseUrl() {
      return this.baseUrls[this.fileId % this.baseUrls.length]
    },
    url() {
      return this.makeUrl()
    },
    urlMobile() {
      return this.makeUrl(this.mobileWidth, this.mobileHeight)
    },

    transformationMode() {
      switch (this.mode) {
        case 'crop':
          return 'c'
        default:
          return ''
      }
    },
  },
  methods: {
    /**
     * Generate URL for image service
     * @param {Number} width - Transformation width, defaults to this.width
     * @param {Number} height - Transformation height, defaults to this.height
     * @param {String} mode - Transformation modes crop ['c', 's', 'cN', ...]
     * @param {String} format - output format (jpg, png) - not implemented.
     */
    makeUrl(width, height, mode, format) {
      width = width || this.width
      height = height || this.height
      mode = mode || this.transformationMode
      const focusPoint = this.focusPoint
      let transformation = `${width}x${height}${mode || ''}`
      const fileName = encodeURIComponent(this.fileName)
      if (mode && focusPoint) {
        transformation += `f${focusPoint.left}x${focusPoint.top}`
      }

      return `${this.baseUrl}/${this.fileId}-${transformation}-${fileName}`
    },
  },
}
</script>

<style lang="scss">
.responsive-image {
  img {
    width: 100%;
    display: block;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    transition: filter 0.7s, transform 0.7s;
  }
}
</style>
