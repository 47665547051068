<template>
  <NuxtLink :to="{ name: 'author-canonical', params: { id: author.id, authorName: authorSlug } }" :rel="'author'">
    <slot />
  </NuxtLink>
</template>

<script>
export default {
  props: {
    author: {
      type: Object,
      required: true,
    },
  },
  computed: {
    authorSlug() {
      return this.$slugify(`${this.author.attributes.firstName} ${this.author.attributes.lastName}`)
    },
  },
}
</script>
